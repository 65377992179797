import React, { Component } from 'react';
import AnimatedNumber from 'react-animated-number';
import { withRouter } from 'react-router';
import alert from './static/alert.svg';
import check from './static/check.svg';
import './App.css';
import QueryString from 'qs';

class App extends Component {
  state = {
    despachos: [],
    naves: [],
    navesOtros: [],
    showCenco: true,
    keyCenco: null,
    keyOtros: null
  };

  urlEndpointCenco = process.env.REACT_APP_URL_ENDPOINT_CENCO;
  urlEndpointOtros = process.env.REACT_APP_URL_ENDPOINT_OTROS;

  componentDidMount() {
    var b64Keys = QueryString.parse(this.props.location.search, { ignoreQueryPrefix: true })._k
    if (b64Keys === "" || b64Keys == null) {
      return
    }
    var keys = atob(b64Keys).split('|')
    this.keyCenco = keys[0]
    this.keyOtros = keys[1]
    this.getItems();
  }

  componentWillUnmount() { }

  toggleShow() {
    this.setState({ showCenco: !this.state.showCenco });
  }

  getItems() {
    if (!this.state.showCenco) {
      fetch(`${this.urlEndpointCenco}?key=${this.keyCenco}`)
        .then(result => result.json())
        .then(result => {
          this.toggleShow()
          this.setState(result);
          setTimeout(
            function () {
              this.getItems();
            }.bind(this),
            10000
          );
        })
        .catch(() => {
          setTimeout(
            function () {
              this.getItems();
            }.bind(this),
            6000
          );
        });
    } else {
      fetch(`${this.urlEndpointOtros}?key=${this.keyOtros}`)
        .then(result => result.json())
        .then(result => {
          this.toggleShow()
          this.setState({ navesOtros: result.naves });
          setTimeout(
            function () {
              this.getItems();
            }.bind(this),
            10000
          );
        })
        .catch(() => {
          setTimeout(
            function () {
              this.getItems();
            }.bind(this),
            6000
          );
        });
    }
  }

  render() {
    let navestmpOtros = this.state.navesOtros.map((nave, i) => {
      nave.pagadoSinRetirar = parseInt(nave.pagadoSinRetirar);
      nave.enPiso = parseInt(nave.enPiso);
      return (
        <div key={i} className={'user-row font-md ' + (i % 2 === 0 ? 'row-bg' : '')}>
          <div className="user-name w-label truncate">{nave.nave}</div>
          <div className="user-data text-center w-10">{nave.fecha}</div>
          <div
            className={
              'user-data text-right w-10 ' + (nave.pagadoSinRetirar === 0 ? 'color-grey' : '')
            }>
            <AnimatedNumber
              stepPrecision={0}
              component="span"
              value={nave.pagadoSinRetirar}
              duration={800}
            />
          </div>
          <div className={'user-data text-right w-10 ' + (nave.enPiso === 0 ? 'color-grey' : '')}>
            <AnimatedNumber stepPrecision={0} component="span" value={nave.enPiso} duration={800} />
          </div>
        </div>
      );
    });
    let navestmp = this.state.naves.map((nave, i) => {
      nave.sinGarantizar = parseInt(nave.sinGarantizar);
      nave.pagadoSinRetirar = parseInt(nave.pagadoSinRetirar);
      nave.enPiso = parseInt(nave.enPiso);
      return (
        <div key={i} className={'user-row font-md ' + (i % 2 === 0 ? 'row-bg' : '')}>
          <div className="user-name w-label truncate">{nave.nave}</div>
          <div className="user-data text-center w-10">{nave.eta}</div>
          <div
            className={
              'user-data text-right w-10 ' + (nave.sinGarantizar === 0 ? 'color-grey' : '')
            }>
            <AnimatedNumber
              stepPrecision={0}
              component="span"
              value={nave.sinGarantizar}
              duration={800}
            />
          </div>
          <div
            className={
              'user-data text-right w-10 ' + (nave.pagadoSinRetirar === 0 ? 'color-grey' : '')
            }>
            <AnimatedNumber
              stepPrecision={0}
              component="span"
              value={nave.pagadoSinRetirar}
              duration={800}
            />
          </div>
          <div className={'user-data text-right w-10 ' + (nave.enPiso === 0 ? 'color-grey' : '')}>
            <AnimatedNumber stepPrecision={0} component="span" value={nave.enPiso} duration={800} />
          </div>
        </div>
      );
    });

    let despachostmp = this.state.despachos.map((despacho, i) => {
      let numero = despacho.numero;
      let fecha = despacho.fechaCd;

      return (
        <div key={i} className={'despacho-row font-md ' + (i % 2 === 0 ? 'row-bg-light' : '')}>
          <div className="user-name w-label truncate">
            <p className="text-show margin-0">{numero}</p>
          </div>
          <div className="user-data text-right w-10">{fecha}</div>
        </div>
      );
    });

    let icon = alert;
    if (this.state.despachos.length === 0) {
      icon = check;
      despachostmp = <p className="text-center margin-0 text-muted">No hay despachos</p>;
    }

    return (
      <div className="App">
        <div className="toolbar text-center">
          <img
            className="logo-agencia"
            src="https://www.rossi.cl/piero/images/diseno/logo_rossi_piero.png"
            alt="Agencia"></img>
        </div>
        <header className="App-header">
          <p className="margin-0">
            <img src={icon} className="left-icon" alt="Atención"></img>
          </p>
          <p className="margin-0 margin-bottom-5">Despachos para visar guía</p>
          <div className="w-100 text-left">
            <div className="despacho-row font-md">
              <div className="user-name w-label">Despacho</div>
              <div className="user-data text-right w-10 color3">
                <p className="margin-0 margin-top--1">Programación CD</p>
              </div>
            </div>
            {despachostmp}
          </div>
        </header>
        <section className="App-right">
          {this.state.showCenco ?
            <div className="w-100 text-left">
              <div className="user-row font-md">
                <div className="user-name w-label">
                  <p className="title">Naves Cencosud</p>
                </div>
                <div className="user-data text-center w-10 color1">
                  <p className="margin-0 small opacity0">-</p>
                  <p className="margin-0 small opacity0">-</p>
                  <p className="margin-0 margin-top--1">ETA</p>
                </div>
                <div className="user-data text-right w-10 color2">
                  <p className="margin-0 small opacity0">-</p>
                  <p className="margin-0 small">Operaciones</p>
                  <p className="margin-0 margin-top--1">Sin Garantizar</p>
                </div>
                <div className="user-data text-right w-10 color3">
                  <p className="margin-0 small">Operaciones</p>
                  <p className="margin-0 small">Arribadas y Pagadas</p>
                  <p className="margin-0 margin-top--1">SIN RETIRAR</p>
                </div>
                <div className="user-data text-right w-10 color4">
                  <p className="margin-0 small opacity0">-</p>
                  <p className="margin-0 small">Operaciones</p>
                  <p className="margin-0 margin-top--1">EN PISO</p>
                </div>
              </div>
              {navestmp}
            </div>
            :
            <div className="w-100 text-left">
              <div className="user-row font-md">
                <div className="user-name w-label">
                  <p className="title">Naves OTROS</p>
                </div>
                <div className="user-data text-center w-10 color1">
                  <p className="margin-0 small opacity0">-</p>
                  <p className="margin-0 small opacity0">-</p>
                  <p className="margin-0 margin-top--1">ETA</p>
                </div>
                <div className="user-data text-right w-10 color3">
                  <p className="margin-0 small">Operaciones</p>
                  <p className="margin-0 small">Arribadas y Pagadas</p>
                  <p className="margin-0 margin-top--1">SIN RETIRAR</p>
                </div>
                <div className="user-data text-right w-10 color4">
                  <p className="margin-0 small opacity0">-</p>
                  <p className="margin-0 small">Operaciones</p>
                  <p className="margin-0 margin-top--1">EN PISO</p>
                </div>
              </div>
              {navestmpOtros}
            </div>
          }
        </section>
      </div>
    );
  }
}

export default withRouter(App);
